import React from 'react';

export function Header () {
  return (
    <h1 className="mt-4 title text-3xl text-center uppercase tracking-widest font-black">
      <svg className="mr-4" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m192 0h-160a32 32 0 0 0 -32 32v160a32 32 0 0 0 32 32h160a32 32 0 0 0 32-32v-160a32 32 0 0 0 -32-32zm-16 120a8 8 0 0 1 -8 8h-112a8 8 0 0 1 -8-8v-16a8 8 0 0 1 8-8h112a8 8 0 0 1 8 8zm16 168h-160a32 32 0 0 0 -32 32v160a32 32 0 0 0 32 32h160a32 32 0 0 0 32-32v-160a32 32 0 0 0 -32-32zm-29.09 140.29a8 8 0 0 1 0 11.31l-11.31 11.31a8 8 0 0 1 -11.31 0l-28.29-28.28-28.29 28.29a8 8 0 0 1 -11.31 0l-11.31-11.32a8 8 0 0 1 0-11.31l28.28-28.29-28.29-28.29a8 8 0 0 1 0-11.31l11.31-11.31a8 8 0 0 1 11.31 0l28.3 28.28 28.29-28.29a8 8 0 0 1 11.31 0l11.31 11.31a8 8 0 0 1 0 11.31l-28.28 28.3zm317.09-428.29h-160a32 32 0 0 0 -32 32v160a32 32 0 0 0 32 32h160a32 32 0 0 0 32-32v-160a32 32 0 0 0 -32-32zm-16 120a8 8 0 0 1 -8 8h-40v40a8 8 0 0 1 -8 8h-16a8 8 0 0 1 -8-8v-40h-40a8 8 0 0 1 -8-8v-16a8 8 0 0 1 8-8h40v-40a8 8 0 0 1 8-8h16a8 8 0 0 1 8 8v40h40a8 8 0 0 1 8 8z" opacity=".4"/><path d="m162.91 360.39-11.31-11.31a8 8 0 0 0 -11.31 0l-28.29 28.29-28.3-28.28a8 8 0 0 0 -11.31 0l-11.31 11.31a8 8 0 0 0 0 11.31l28.29 28.29-28.28 28.29a8 8 0 0 0 0 11.31l11.31 11.32a8 8 0 0 0 11.31 0l28.29-28.29 28.29 28.28a8 8 0 0 0 11.31 0l11.31-11.31a8 8 0 0 0 0-11.31l-28.28-28.29 28.28-28.3a8 8 0 0 0 0-11.31zm181.09-232.39h40v40a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8v-40h40a8 8 0 0 0 8-8v-16a8 8 0 0 0 -8-8h-40v-40a8 8 0 0 0 -8-8h-16a8 8 0 0 0 -8 8v40h-40a8 8 0 0 0 -8 8v16a8 8 0 0 0 8 8zm-176-32h-112a8 8 0 0 0 -8 8v16a8 8 0 0 0 8 8h112a8 8 0 0 0 8-8v-16a8 8 0 0 0 -8-8zm312 192h-160a32 32 0 0 0 -32 32v160a32 32 0 0 0 32 32h160a32 32 0 0 0 32-32v-160a32 32 0 0 0 -32-32zm-16 152a8 8 0 0 1 -8 8h-112a8 8 0 0 1 -8-8v-16a8 8 0 0 1 8-8h112a8 8 0 0 1 8 8zm0-64a8 8 0 0 1 -8 8h-112a8 8 0 0 1 -8-8v-16a8 8 0 0 1 8-8h112a8 8 0 0 1 8 8z"/></svg>
      Tradefinder
    </h1>
  )
}
